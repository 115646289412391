/*
 *  environment-template.ts
 *  This is the environment-template which is used to generate environment.ts through script. The Constant in this will be replaced by.
    by the values comming from AWS Secrets   
 */


export const environment = {
  production: false,
  API_URL: 'https://dev-api.vendor.arhomes.com/',
  SHA_ACCESS_PHRASE1: 'ARHEST1953',
  SHA_ACCESS_PHRASE2: 'NEWARH',
  SHA_ACCESS_IV: '5183666c72eec9e4',
  TOTAL_IMG_COUNT: 20,
  MIN_SEARCH_LENGTH:3,
  SMART_LOOK_ID: '240a852c106a534472bf9a0524e93b8eb4e22813',
  SMART_LOOK_REGION: 'eu',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
